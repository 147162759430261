<template>
<div> 
	<div class="notfound" >{{$t("errors.notfound")}}</div>
	<img :src="'../img/blackholedraw.gif'" class="gif"/>
</div>
</template>

<script>

export default {
	components: {
	}
}

</script>


<style scoped lang="scss" src="../css/pages/404.scss"/>
